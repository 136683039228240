<template>
  <div class="flix-well">
    <div class="flix-text-center flix-form-group">
      <h3 class="flix-html-h3"><flixIcon :id="'plus'" /> {{ amount }} {{ $t('message.credit') }}</h3>
      <h2 class="flix-html-h2 flix-text-success">{{ data.currency }} {{ price.price.toString().split('.').join(',') }}</h2>
      <h5 class="flix-html-h5">{{ $t('message.includeTax', {tax: data.currency + ' ' + price.tax.toString().split('.').join(',')}) }}</h5>
    </div>
    <div ref="paypalScript"></div>
    <div v-show="!handlePay"><div ref="paypalButton"></div></div>
    <flixLoader v-if="handlePay" />
  </div>
</template>
<script>
import { paypalJS } from '@/components/credits/new/paypalBtn.js'
export default {
  components: {},
  props: {
    data: Object,
    amount: Number,
    price: Object,
    setSuccess: Function
  },
  data () {
    return {
      handlePay: false,
      variables: this.$getUserVariables(),
      paypal_access: {
        sandbox: 'AWuTJsThZ2o_1CffWZYaefeMLPtpwM7IkMcgF9nRRDB4ko61Kw2p-Cl5M0nuFV9oqhird0PTZk5nCqx3',
        production: 'AZJUOV0SedQQzu-dYhzGb8b9qSCMZ-TZdgNDhbWh25mS6cCmJGk0smabiymoekzGvK9ESsK_nzWz_pKy'
      }
    }
  },
  methods: {
    getCreateInnovoice (data) {
      this.$flix_post({
        url: '/credit/create_innovoice',
        data: data,
        callback: function (ret) {
          this.getSendInnovoice(data)
        }.bind(this)
      })
    },
    getSendInnovoice (data) {
      this.$flix_post({
        url: '/credit/send_innovoice',
        data: data,
        callback: function (ret) {
          this.setSuccess()
          this.handlePay = false
        }.bind(this)
      })
    },
    getHandlePay (data) {
      var send = {
        user: this.variables.user.md5_id,
        data: data
      }
      this.$flix_post({
        url: '/credit/pay',
        data: send,
        callback: function (ret) {
          if (!ret.data[0]) {
            return false
          }
          this.getCreateInnovoice({ user: send.user, ID: ret.data[1].ID })
          this.getSetNewCredits()
        }.bind(this)
      })
    },
    getSetNewCredits () {
      this.$store.commit('addCredits', this.amount)
    },
    getPaypalButton () {
      var settings = {
        onCancel: function (data, actions) {
          this.handlePay = false
        }.bind(this),
        createOrder: function (data, actions) {
          this.handlePay = true
          return actions.order.create({
            purchase_units: [{

              description: this.amount + ' ' + this.$t('message.credit'),

              amount: {
                value: this.price.price
              }

            }]
          })
        }.bind(this),
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            var ret = {
              amount: this.amount,
              price: this.price.price,
              tax: this.price.tax,
              tax_percent: this.data.tax,
              currency: this.data.currency,
              discount: this.price.discount,
              cost_per_unit: this.data.unit,
              server_response: details
            }
            if (ret.server_response.status.toUpperCase() === 'COMPLETED') {
              this.getHandlePay(ret)
            } else {
              this.handlePay = false
            }
          }.bind(this))
        }.bind(this)

      }

      /*eslint-disable */
      paypal.Buttons(settings).render(this.$refs.paypalButton)
      /* eslint-enable */
    },
    loadPaypal () {
      var p = new paypalJS()
      p.client_id = this.paypal_access.production
      p.currency = this.data.currency
      p.locale = this.data.locale
      p.onload = function () { this.getPaypalButton() }.bind(this)
      p.build(this.$refs.paypalScript)
    }
  },
  mounted () {
    this.loadPaypal()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
