var paypalJS = function () {
  this.url = 'https://www.paypal.com/sdk/js'
  this.client_id = false
  this.currency = false
  this.locale = false
  this.onload = function () { }

  this.build = function (g) {
    var script = document.createElement('script')
    script.src = this.url + '?client-id=' + this.client_id + '&currency=' + this.currency + '&locale=' + this.locale + '&vault=false'
    script.setAttribute('type', 'text/javascript')
    g.appendChild(script)
    script.onload = function () { this.onload() }.bind(this)
  }
}
export { paypalJS }
